import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container } from "./utils"
import logo from "../images/logo-multi-green.svg"
import { ItemsList } from "./utils"

const HeaderWhite = () => (
    <CustomContainer>
      <img src={logo} alt="Multi Talks Logo" />
    </CustomContainer>
)

const CustomContainer = styled(Container)`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  img {
    width: 160px;
  }
`

const CustomItemList = styled(ItemsList)`
  display: flex;
  flex-direction: row;
  align-items: center;
  li {
    margin-left: -1px;
    a {
      position: relative;
      font-weight: 900;
      text-decoration: none;
      font-size: ${DesignTokens.fontSizes[3]};
      color: ${DesignTokens.colors.primary[500]};
      border: 1px solid ${DesignTokens.colors.primary[300]};
      display: block;
      padding: ${DesignTokens.spacing[2]};
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border: 1px solid ${DesignTokens.colors.white};
        z-index: 10;
      }
      @media screen and (min-width: 769px) {
        font-size: ${DesignTokens.fontSizes[4]};
      }
    }
    &:last-child {
      a {
        width: auto;
        padding: 0 ${DesignTokens.spacing[5]};
      }
    }
  }
`

export default HeaderWhite
