import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"
import HeaderWhite from "../components/headerWhite"
import { Container } from "../components/utils"

const Contacts = ({ location }) => {
  console.log(location)
  return (
    <Layout>
      {/* <SEO title="Business Expander" /> */}
      <HeaderWhite />
      <Container>
        <ContactForm email={location.state ? location.state.email : null} />
      </Container>
    </Layout>
  )
}

export default Contacts
