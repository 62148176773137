import React, { useState } from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"

const ContactForm = ({ email }) => {
  const [visitor, setEmail] = useState(email)
  return (
    <Form>
      <form
        method="post"
        name="contact"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <section>
          <label htmlFor="email">Email *</label>
          <input
            onChange={e => setEmail(e.target.value)}
            type="text"
            name="email"
            id="email"
            value={visitor}
            required
          />
        </section>
        <section>
          <label htmlFor="name">Nome e cognome *</label>
          <input type="text" name="name" id="name" required />
        </section>
        <section>
          <label htmlFor="phone">Numero di telefono</label>
          <input type="tel" name="phone" id="phone" />
        </section>
        <section>
          <label htmlFor="message">Messaggio</label>
          <textarea name="message" id="message" rows="6" />
        </section>
        <section>
          <p>
            Dopo aver preso visione della presente informativa sulla privacy ai
            sensi dell'ART.13 D.Lgs. n.196 del 2003, a) Acconsento al
            trattamento dei dati personali al fine esclusivo di avere
            informazioni riguardo i servizi relativi al presente sito web. Per
            completare la registrazione è necessaria l'autorizzazione al
            trattamento dei dati personali.
          </p>
        </section>
        <Checkbox>
          <input
            type="checkbox"
            id="privacy"
            name="privacy"
            required
          />
          <label htmlFor="privacy">Si, acconsento</label>
        </Checkbox>
        <section>
          <p>
            Acconsento al trattamento dei dati personali al fine di ricevere
            materiale pubblicitario ed informazioni commerciali a fini di
            marketing riguardanti i servizi via e mail,newsletter, fax o posta
            ordinaria
          </p>
        </section>
        <InputGroup>
          <RadioButton>
            <input
              type="radio"
              id="yes"
              name="trattamento"
              required
            />
            <label htmlFor="yes">Si, acconsento</label>
          </RadioButton>
          <RadioButton>
            <input type="radio" id="no" name="trattamento" />
            <label htmlFor="no">No, non acconsento</label>
          </RadioButton>
        </InputGroup>
        <section>
          <input type="submit" value="Invia messaggio" />
        </section>
      </form>
    </Form>
  )
}

export default ContactForm

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  div {
    margin: 0 ${DesignTokens.spacing[2]};
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

const Checkbox = styled.div`
  display: flex;
  flex-direction: row;
  input {
    margin-right: 0.5rem;
  }
  label {
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 0 !important;
  }
`

const RadioButton = styled.div`
  display: flex;
  flex-direction: row;
  input {
    margin-right: 0.5rem;
  }
  label {
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 0 !important;
  }
`

const Form = styled.div`
  form {
    width: 600px;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    label {
      margin-bottom: ${DesignTokens.spacing[4]};
      text-transform: lowercase;
      font-weight: bold;
      color: #515a61;
    }
    input,
    textarea {
      padding: ${DesignTokens.spacing[6]};
      border: 1px solid ${DesignTokens.colors.black};
      &:hover,
      &:focus {
        border: 1px solid ${DesignTokens.colors.primary[500]};
      }
    }

    input[type="submit"] {
      background: ${DesignTokens.colors.primary[500]};
      border: none;
      color: ${DesignTokens.colors.white};
      border-radius: ${DesignTokens.borderRadius};
      text-transform: uppercase;
      font-weight: bold;
      &:hover {
        background: ${DesignTokens.colors.primary[500]};
      }
      &:active {
        background: ${DesignTokens.colors.primary[500]};
      }
    }
    section {
      display: flex;
      flex-direction: column;
      p {
        font-size: 12px;
        color: #555555;
      }
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[4]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
